import React, { memo, useEffect } from 'react'
import { navigate } from '@reach/router'
import { Link } from '@elements'
import { NewsCard } from '@elements'
import { ArrowPaginate } from '@elements'
import ArrowIcon from '@images/arrow_icon.svg'
import NewsCardPicture from '@images/news_card_1.png'
import styles from './Post.module.scss'

interface NewsPostProps {
  id: string
}

const News: React.FC<NewsPostProps> = props => {
  useEffect(() => {
    navigate('/')
  })

  return null
  // <>
  //   <a className={styles.back} href="/news">
  //     <ArrowIcon className={styles.back__arrow} />
  //   </a>
  //   <div className={styles.post_wrapper}>
  //     <section className={styles.post}>
  //       <img className={styles.post__image} src={NewsCardPicture} alt="Иллюстрация карочки новостей"></img>
  //       <article className={styles.post__article}>
  //         <div className={styles.post__article__head}>
  //           <span className={styles.post__date}>14.12.2020</span>
  //           <Link className="text__link red" to="#" icon={true}>
  //             Инстаграм
  //           </Link>
  //         </div>
  //         <p className={styles.post__article__text}>
  //           Вы просили — мы сделали!
  //           <br />
  //           Теперь Английский клуб будет проходить в будние дни!
  //           <br />
  //           Кроме того, первая встреча будет в тестовом режиме и совсем бесплатно.
  //           <br />
  //           А значит, уже в эту среду в центральном зале вы сможете пообщаться с <br />
  //           native-спикером, обсудить путешествия, актуальные новости или <br />
  //           поговорить на философские вопросы в рамках темы клуба и убедиться <br />
  //           как это захватывающе! <a href="/">#c52клуб</a>
  //           <br />
  //           <br />
  //           Когда: Среда 19:00
  //           <br />
  //           Участие: бесплатно
  //           <br />
  //         </p>
  //       </article>
  //     </section>
  //     <ArrowPaginate onClick={() => null} title="СЛЕДУЮЩАЯ" direction="next" />
  //   </div>
  //   <div className={styles.post__line}></div>
  //   <section className={styles.post__section}>
  //     <NewsCard hideArrow={true} />
  //     <NewsCard hideArrow={true} />
  //     <NewsCard hideArrow={true} />
  //     <NewsCard hideArrow={true} />
  //     <NewsCard hideArrow={true} />
  //     <NewsCard hideArrow={true} />
  //   </section>
  // </>
}

export default memo(News)
